import { environment } from '@env';

if (!environment.supabaseUrl) throw new Error('Missing supabaseUrl config.');

export const TOUR_GUIDE_VIEWED_STORAGE_KEY = `terrai-${environment.stage}-tour-guide-viewed`;

export const clearTourGuideViewedFromLocalStorage = () =>
  localStorage.removeItem(TOUR_GUIDE_VIEWED_STORAGE_KEY);

export const setTourGuideViewedInLocalStorage = (tourGuideViewed: boolean) =>
  localStorage.setItem(TOUR_GUIDE_VIEWED_STORAGE_KEY, String(tourGuideViewed));

export const getTourGuideViewedFromLocalStorage = () =>
  localStorage.getItem(TOUR_GUIDE_VIEWED_STORAGE_KEY) === 'true';
