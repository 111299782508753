import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { enqueueSnackbar } from 'notistack';

import { getResolvedEndpoint } from '@/api/utils/getResolvedEndpoint';
import { handleApiCall } from '@/api/utils/handleApiCall';
import { MutationStateFns } from '@/api/utils/mutation';
import { useAuth } from '@/context/AuthProvider';

export type ApiRequest_Invite_Tenants = {
  tenantIds: number[];
  subject?: string;
  body?: string;
};

export type ApiResponse_Invite_Tenants = Array<{
  tenantId: number;
  success: boolean;
  error?: string;
}>;

const METHOD = 'POST';
const ENDPOINT = 'tenant/invite';

export const useInviteTenants = (
  buildingId: number,
  stateFns?: MutationStateFns<ApiResponse_Invite_Tenants>
) => {
  const { session } = useAuth();

  return useMutation({
    mutationFn: async (data: ApiRequest_Invite_Tenants) =>
      handleApiCall<ApiResponse_Invite_Tenants, ApiRequest_Invite_Tenants>(
        session,
        METHOD,
        getResolvedEndpoint(ENDPOINT, {}),
        data
      ),
    onSuccess: data => {
      enqueueSnackbar('Tenants invited successfully', { variant: 'success' });
      stateFns?.onSuccess?.(data);
    },
    onError: (err: AxiosError) => {
      enqueueSnackbar('Failed to invite tenants', { variant: 'error' });
      // eslint-disable-next-line no-console
      console.error(err);
      stateFns?.onError?.(err);
    },
  });
};
