import { useQuery } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';

import { AccountType } from '@/api/enums/supabase';
import { PropertyLocationModel } from '@/api/models/propertyLocation';
import { getQueryKey } from '@/api/utils/getQueryKey';
import { getResolvedEndpoint } from '@/api/utils/getResolvedEndpoint';
import { handleApiCall } from '@/api/utils/handleApiCall';
import { useAuth } from '@/context/AuthProvider';

export type ApiResponse_GetOnboardingProgress_PropertyManager = {
  hasPropertyLocation: boolean;
  hasPhoneNumber: boolean;
  hasEmailAddress: boolean;
  hasBuilding: boolean;
  hasUnit: boolean;
  hasTenant: boolean;
  hasLeaseDocument: boolean;
  hasTenantsWithOnboardingEmailSent: boolean;
  hasStripeConnected: boolean;
  firstPropertyLocationId: number | null;
  firstBuildingId: number | null;
  firstUnitId: number | null;
  firstTenantId: number | null;
};

const METHOD = 'GET';
const ENDPOINT = 'property-manager/onboarding/:id/progress';
export const QUERY_KEY = (id?: PropertyLocationModel['id']) =>
  getQueryKey(METHOD, ENDPOINT, { id });

export const useGetOnboardingProgress = (id?: PropertyLocationModel['id'], enabled = true) => {
  const { user, session } = useAuth();

  return useQuery({
    enabled: enabled && !!session?.access_token,
    queryKey: QUERY_KEY(id),
    queryFn: async () => {
      try {
        if (user?.user_metadata.account_type !== AccountType.property_manager || !id) {
          return {
            hasPropertyLocation: false,
            hasPhoneNumber: false,
            hasBuilding: false,
            hasUnit: false,
            hasTenant: false,
            hasLeaseDocument: false,
            hasTenantsWithOnboardingEmailSent: false,
            firstPropertyLocationId: null,
            firstBuildingId: null,
            firstUnitId: null,
            firstTenantId: null,
          } as ApiResponse_GetOnboardingProgress_PropertyManager;
        }

        const data = await handleApiCall<ApiResponse_GetOnboardingProgress_PropertyManager>(
          session,
          METHOD,
          getResolvedEndpoint(ENDPOINT, {
            id: id.toString(),
          })
        );

        return data;
      } catch (err) {
        enqueueSnackbar('Failed to fetch onboarding progress.', { variant: 'error' });

        // eslint-disable-next-line no-console
        console.error(err);

        throw err;
      }
    },
  });
};
