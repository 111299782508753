import { Box, Button, Divider, Stack, Typography, useTheme } from '@mui/material';
import confetti from 'canvas-confetti';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { PMPRoutes } from '@/config/routes';
import { useAuth } from '@/context/AuthProvider';
import { useOnboardingProgress } from '@/context/OnboardingProgressProvider';
import { usePropertyManager } from '@/context/PropertyManagerProvider';
import { getChecklistItems, getLastCompletedItem, getNextItem } from '@/utils/checklist';

import TerriLottie from '../lottie/TerriLottie';
import ModalWithPreview from '../modals/ModalWithPreview';

const OnboardingProgressModal: React.FC = () => {
  const { user } = useAuth();
  const { onboardingProgress, isOnboardingComplete, setIsMenuTourGuideOpen } =
    useOnboardingProgress();
  const { isSingleFamilyLocation, selectedPropertyLocation } = usePropertyManager();
  const initialIsOnboardingComplete = useRef(isOnboardingComplete);

  const checklistItems = useMemo(
    () =>
      getChecklistItems({
        isSingleFamilyLocation,
        onboardingProgress,
        selectedPropertyLocation,
        user,
      }),
    [isSingleFamilyLocation, onboardingProgress, selectedPropertyLocation, user]
  );

  const [isOpen, setIsOpen] = useState(!isOnboardingComplete);
  const [isAutoOpenned, setIsAutoOpenned] = useState(true);

  const theme = useTheme();

  const nextItem = useMemo(() => getNextItem(checklistItems), [checklistItems]);
  const lastItem = useMemo(() => getLastCompletedItem(checklistItems), [checklistItems]);

  const handleOnClose = useCallback(
    (shouldOpenMenuTourGuide = false) => {
      setIsOpen(false);
      setIsAutoOpenned(false);

      if (shouldOpenMenuTourGuide) {
        setIsMenuTourGuideOpen(true);
      }
    },
    [setIsMenuTourGuideOpen]
  );

  useEffect(() => {
    if (!isOpen) return;
    if (isAutoOpenned) return;

    confetti({
      particleCount: 100,
      spread: 70,
      origin: { y: 0.6 },
      colors: [
        theme.palette.primary.lighter,
        theme.palette.primary.light,
        theme.palette.primary.main,
        theme.palette.primary.dark,
        theme.palette.primary.darker,
      ],
      zIndex: 9000,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, isAutoOpenned]);

  useEffect(() => {
    if (initialIsOnboardingComplete.current) return;

    if (isOnboardingComplete) {
      initialIsOnboardingComplete.current = true;
    }

    setIsOpen(true);
  }, [onboardingProgress, isOnboardingComplete]);

  if (!isOpen) return null;

  return (
    <ModalWithPreview
      fullScreen={false}
      maxWidth="md"
      title={isOnboardingComplete ? 'Onboarding complete!' : "Let's finish your onboarding"}
      onClose={() => handleOnClose(false)}
      Actions={
        <>
          <Button variant="outlined" color="secondary" onClick={() => handleOnClose(false)}>
            Close
          </Button>

          {!!nextItem && (
            <Button
              variant="contained"
              color="primary"
              href={nextItem.route}
              onClick={() => handleOnClose(false)}
            >
              {nextItem.actionLabel}
            </Button>
          )}

          {!nextItem && isOnboardingComplete && (
            <Button
              variant="contained"
              color="primary"
              href={PMPRoutes.dashboard}
              onClick={() => handleOnClose(true)}
            >
              Take Guided Tour
            </Button>
          )}
        </>
      }
    >
      <Stack direction="row" spacing={2} alignItems="center">
        <Box width={128} height={128}>
          <TerriLottie size={128} />
        </Box>

        <Stack direction="column" spacing={1}>
          {!!lastItem && !isAutoOpenned && !isOnboardingComplete && (
            <>
              <Typography variant="body2">
                You've <strong>{lastItem?.completedTitle}</strong>, good job!
              </Typography>

              <Divider sx={{ my: 2 }} />
            </>
          )}

          {nextItem ? (
            <>
              <Typography variant="body2">
                {isAutoOpenned ? "Let's" : "Now, let's"} <strong>{nextItem.letsTitle}</strong>.
              </Typography>

              {!!nextItem.benefits?.length && (
                <Typography variant="body2">
                  {nextItem.letsDescription ?? 'This'} will allow you to:
                  <Box component="ul" sx={{ pl: 2, my: 0.5 }}>
                    {nextItem.benefits?.map(benefit => (
                      <li key={benefit}>{benefit}</li>
                    ))}
                  </Box>
                </Typography>
              )}
            </>
          ) : (
            <>
              <Typography variant="body2">
                You've completed <strong>all setup steps</strong>!
              </Typography>

              <Typography variant="body2">Your property management system is now ready.</Typography>

              <Divider sx={{ my: 2 }} />

              <Typography variant="body2">
                I'll be in touch if I need anything, but in the meantime, feel free to upload any
                additional documents, invoices, or warranties for your property. The more I know,
                the better I can support you and proactively assist with your tenant's questions!
              </Typography>

              <Typography variant="body2">
                I’ll also keep you in the loop with real-time alerts whenever a tenant reaches out,
                so you’re always up to date!
              </Typography>
            </>
          )}
        </Stack>
      </Stack>
    </ModalWithPreview>
  );
};

export default OnboardingProgressModal;
