import Box from '@mui/material/Box';
import MUIDrawer from '@mui/material/Drawer';
import { Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useMemo } from 'react';

import { DRAWER_WIDTH } from '@/@mantis/config';
import { useDrawer } from '@/@mantis/contexts/DrawerContext';

import DrawerContent from './DrawerContent';
import DrawerHeader from './DrawerHeader';
import MiniDrawerStyled from './MiniDrawerStyled';

interface Props {
  window?: () => Window;
}

const Drawer: React.FC<Props> = ({ window }) => {
  const { isOpen, setIsOpen } = useDrawer();
  const downLG = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

  // responsive drawer container
  const container = window !== undefined ? () => window().document.body : undefined;

  // header content
  const drawerContent = useMemo(() => <DrawerContent isOpen={isOpen} />, [isOpen]);
  const drawerHeader = useMemo(() => <DrawerHeader isOpen={isOpen} />, [isOpen]);

  return (
    <Box component="nav" sx={{ flexShrink: { md: 0 }, zIndex: 1200 }} aria-label="mailbox folders">
      {!downLG ? (
        <MiniDrawerStyled variant="permanent" open={isOpen}>
          {drawerHeader}
          {drawerContent}
        </MiniDrawerStyled>
      ) : (
        <MUIDrawer
          container={container}
          variant="temporary"
          open={isOpen}
          onClose={() => setIsOpen(!isOpen)}
          ModalProps={{ keepMounted: true }}
          sx={{
            display: { xs: isOpen ? 'block' : 'none', lg: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: DRAWER_WIDTH,
              borderRight: '1px solid',
              borderRightColor: 'divider',
              backgroundImage: 'none',
              boxShadow: 'inherit',
              bgcolor: 'error.0',
            },
          }}
        >
          {drawerHeader}
          {drawerContent}
        </MUIDrawer>
      )}
    </Box>
  );
};

export default Drawer;
