import { Box, useMediaQuery, useTheme } from '@mui/material';

import Logo from '@/@mantis/components/logo';

import MenuTourGuide from '@/components/common/MenuTourGuide';

import DrawerHeaderStyled from './DrawerHeaderStyled';

interface Props {
  isOpen: boolean;
}

const DrawerHeader: React.FC<Props> = ({ isOpen }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <DrawerHeaderStyled
      open={isOpen}
      sx={{
        minHeight: '60px',
        width: 'initial',
        paddingTop: '8px',
        paddingBottom: '8px',
        paddingLeft: isOpen ? '24px' : 0,
        paddingRight: isOpen ? '12px' : 0,
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Logo isIcon={!isOpen} sx={{ width: isOpen ? 'auto' : 35, height: 35 }} />

      {isOpen && !isMobile && (
        <Box
          sx={{
            marginLeft: 'auto',
          }}
        >
          <MenuTourGuide />
        </Box>
      )}
    </DrawerHeaderStyled>
  );
};

export default DrawerHeader;
