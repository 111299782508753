import { useQuery } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';

import { BuildingModel } from '@/api/models/building';
import { getQueryKey } from '@/api/utils/getQueryKey';
import { getResolvedEndpoint } from '@/api/utils/getResolvedEndpoint';
import { handleApiCall } from '@/api/utils/handleApiCall';
import { useAuth } from '@/context/AuthProvider';

export type ApiResponse_GetTenantInviteTemplate = {
  subject: string;
  body: string;
};

const METHOD = 'GET';
const ENDPOINT = 'tenant/invite-template/:buildingId';
export const QUERY_KEY = (buildingId: BuildingModel['id']) =>
  getQueryKey(METHOD, ENDPOINT, { buildingId });

export const useGetTenantInviteTemplate = (buildingId: BuildingModel['id'], enabled = true) => {
  const { session } = useAuth();

  return useQuery({
    enabled: enabled && !!session?.access_token,
    queryKey: QUERY_KEY(buildingId),
    queryFn: async () => {
      try {
        const data = await handleApiCall<ApiResponse_GetTenantInviteTemplate>(
          session,
          METHOD,
          getResolvedEndpoint(ENDPOINT, {
            buildingId: buildingId.toString(),
          })
        );

        return data;
      } catch (err) {
        enqueueSnackbar('Failed to fetch invite template.', { variant: 'error' });
        throw err;
      }
    },
  });
};
