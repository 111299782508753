import { Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import Box from '@mui/material/Box';

import { usePropertyManager } from '@/context/PropertyManagerProvider';
import { formatPhoneNumber } from '@/utils/phone';

const HeaderContent: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { selectedPropertyLocation } = usePropertyManager();

  return (
    <>
      <Box sx={{ width: '100%', ml: 1 }} />

      {selectedPropertyLocation && (
        <Stack spacing={0.25} alignItems="flex-end" sx={{ mr: 2 }}>
          {!!selectedPropertyLocation.email && (
            <Typography variant={isMobile ? 'body2' : 'body3'}>
              {selectedPropertyLocation.email.value}
            </Typography>
          )}

          {!!selectedPropertyLocation.phone && (
            <Typography variant={isMobile ? 'body2' : 'body3'}>
              {formatPhoneNumber(selectedPropertyLocation.phone.value)}
            </Typography>
          )}
        </Stack>
      )}

      {/* {!downLG && <FullScreen />} */}
      {/* <Profile /> */}
    </>
  );
};

export default HeaderContent;
